<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="12">
        <v-select
          v-model="type"
          :items="types"
          item-text="name"
          item-value="value"
          label="Typ"
          filled
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="query"
          placeholder="Suchbegriff"
          type="text"
          hint="Suche nach einem bestimmten Wert in der Datenbank. % als Wildcard (%gmx.de%)."
          persistent-hint
          filled
        ></v-text-field>
      </v-col>

      <v-col lg="2" cols="6">
        <v-text-field
          v-model="companies_id"
          placeholder="Company-ID (alle)"
          type="text"
          filled
        ></v-text-field>
      </v-col>

      <v-col lg="2" cols="6">
        <v-text-field
          v-model="campaigns_id"
          placeholder="Kampagne-ID (alle)"
          type="text"
          filled
        ></v-text-field>
      </v-col>

      <v-col lg="2" cols="6">
        <v-text-field
          v-model="providers_id"
          placeholder="Provider-ID (alle)"
          type="text"
          filled
        ></v-text-field>
      </v-col>

      <v-col lg="2" cols="6">
        <v-text-field
          v-model="leads_id"
          placeholder="Lead-ID (alle)"
          type="text"
          filled
        ></v-text-field>
      </v-col>

      <v-col lg="2" cols="6">
        <v-select
          v-model="requestMethod"
          :items="requestMethods"
          item-text="name"
          item-value="value"
          label="Request method"
          filled
        ></v-select>
      </v-col>

      <v-col lg="2" cols="6">
        <v-select
          v-model="responseStatusCode"
          :items="responseStatusCodes"
          item-text="name"
          item-value="value"
          label="Response status code"
          filled
        ></v-select>
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model="limit"
          placeholder="Limit"
          type="number"
          hint="Anzahl der Datensätze"
          persistent-hint
          filled
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="sortBy"
          :items="sortBys"
          item-text="name"
          item-value="value"
          label="Sortierung nach"
          filled
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="orderBy"
          :items="orderBys"
          item-text="name"
          item-value="value"
          label="Sortierung"
          filled
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-btn @click="getData">Suchen</v-btn>
      </v-col>
    </v-row>

    <v-divider class="ma-6"></v-divider>

    <v-text-field
      v-model="searchProp"
      append-icon="mdi-card-search"
      label="Suchbegriff"
      placeholder="Was suchst Du?"
      solo
      style="max-width: 500px"
    ></v-text-field>

    <v-data-table
      :loading="preloader"
      :headers="headers"
      :items="data"
      :items-per-page="20"
      :search="searchProp"
      class="elevation-1"
    >
      <template v-slot:item.responseBody="{ item }">
        <div
          style="
            max-height: 200px;
            max-width: 400px;
            overflow-y: auto;
            margin: 1rem 0;
            padding: 0.5rem;
            border: 1px solid lightgray;
            border-radius: 5px;
          "
        >
          <pre>{{ JSON.parse(item.responseBody) }}</pre>
        </div>
      </template>

      <template v-slot:item.requestBody="{ item }">
        <div
          style="
            max-height: 200px;
            max-width: 400px;
            overflow-y: auto;
            margin: 1rem 0;
            padding: 0.5rem;
            border: 1px solid lightgray;
            border-radius: 5px;
          "
        >
          <pre>{{ JSON.parse(item.requestBody) }}</pre>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
export default {
  name: "ApiDebugger",

  components: {
    PageHeader,
  },

  data() {
    return {
      pageTitle: "API-LogDebugger",
      pageDescription: `<p>Einsehen der API-Logs</p>`,
      title: "",
      preloader: false,
      data: [],
      headers: [],
      componentKey: 0,
      type: "",
      query: "",
      searchProp: "",
      types: [
        { name: "Alle", value: "" },
        { name: "HTTP", value: "http" },
        { name: "E-Mail", value: "email" },
      ],
      logLevel: "",
      logLevels: [
        { name: "Alle", value: "" },
        { name: "Info", value: "info" },
        { name: "Warning", value: "warning" },
        { name: "Error", value: "error" },
        { name: "Emergency", value: "emergency" },
      ],
      tableName: "",
      action: "",
      actions: [
        { name: "Alle", value: "" },
        { name: "Create", value: "create" },
        { name: "Update", value: "update" },
        { name: "Delete", value: "delete" },
      ],
      limit: 20,
      offset: 0,
      sortBy: "id",
      orderBy: "DESC",
      sortBys: [
        { name: "ID", value: "id" },
        { name: "Created", value: "created" },
        { name: "Action", value: "action" },
        { name: "Level", value: "level" },
        { name: "Type", value: "type" },
      ],
      orderBys: [
        { name: "Aufsteigend", value: "ASC" },
        { name: "Absteigend", value: "DESC" },
      ],
      searchIn: "data",
      searchIns: [
        { name: "Daten", value: "data" },
        { name: "json", value: "json" },
      ],
      companies_id: "",
      campaigns_id: "",
      providers_id: "",
      leads_id: "",
      requestMethod: "",
      requestMethods: [
        { name: "Alle", value: "" },
        { name: "GET", value: "GET" },
        { name: "POST", value: "POST" },
        { name: "PATCH", value: "PATCH" },
        { name: "PUT", value: "PUT" },
        { name: "DELETE", value: "DELETE" },
      ],
      responseStatusCode: "",
      responseStatusCodes: [
        { name: "Alle", value: "" },
        { name: "200", value: "200" },
        { name: "201", value: "201" },
        { name: "400", value: "400" },
        { name: "401", value: "401" },
        { name: "403", value: "403" },
        { name: "404", value: "404" },
        { name: "500", value: "500" },
      ],
    };
  },

  methods: {
    /* 

    Für die Tabelle "apiLog" müssen wir eine separate Route/Actions/Domain verwenden: Neue Route GET /apiLogs. Mögliche Parameter:

    filter[type]={type} e.g. http|email
    filter[companies_id]={companies_id}
    filter[campaigns_id]={campaigns_id}
    filter[providers_id]={providers_id}
    filter[leads_id]={leads_id}
    filter[requestMethod]={requestMethod} e.g. GET|POST|PATCH|PUT|DELETE
    filter[responseStatusCode]={responseStatusCode} e.g. 400

    filter[responseBody]={searchString} e.g. %teilstring%
    limit={limit} default:20
    offset={offset} default:0
    sortBy={sortBy} e.g. id|companies_id|campaigns_id|providers_id|leads_id|created (default:id)
    orderBy={orderBy} e.g. ASC|DESC (default:DESC)

    Beispiel: GET https://apislim.devel.mitfit.de/v1/apiLogs?filter[type]=http&filter[responseBody]=%error%

    Zusätzlich wird "leadFullName" zurückgegeben (ungleich NULL, wenn leads_id IS NOT NULL).

    */

    async getData() {
      let filter = "";
      let setup = "";

      if (this.type) {
        if (filter) filter += "&";
        else filter += "?";
        filter += `filter[type]=${this.type}`;
      }

      if (this.companies_id) {
        if (filter) filter += "&";
        else filter += "?";
        filter += `filter[companies_id]=${this.companies_id}`;
      }

      if (this.campaigns_id) {
        if (filter) filter += "&";
        else filter += "?";
        filter += `filter[campaigns_id]=${this.campaigns_id}`;
      }

      if (this.providers_id) {
        if (filter) filter += "&";
        else filter += "?";
        filter += `filter[providers_id]=${this.providers_id}`;
      }

      if (this.leads_id) {
        if (filter) filter += "&";
        else filter += "?";
        filter += `filter[leads_id]=${this.leads_id}`;
      }

      if (this.requestMethod) {
        if (filter) filter += "&";
        else filter += "?";
        filter += `filter[requestMethod]=${this.requestMethod}`;
      }

      if (this.responseStatusCode) {
        if (filter) filter += "&";
        else filter += "?";
        filter += `filter[responseStatusCode]=${this.responseStatusCode}`;
      }

      if (this.query) {
        // check, if query contains a % sign. if not, wrap it with % signs
        if (!this.query.includes("%")) {
          this.query = `%${this.query}%`;
        }

        if (filter) filter += "&";
        else filter += "?";
        filter += `filter[responseBody]=${encodeURIComponent(this.query)}`;
      }

      if (this.limit) {
        if (!filter) {
          if (!setup) {
            setup += `?limit=${this.limit}`;
          } else {
            setup += `&limit=${this.limit}`;
          }
        } else {
          setup += `&limit=${this.limit}`;
        }
      }

      if (this.offset) {
        if (!filter) {
          if (!setup) {
            setup += `?offset=${this.offset}`;
          } else {
            setup += `&offset=${this.offset}`;
          }
        } else {
          setup += `&offset=${this.offset}`;
        }
      }

      if (this.sortBy) {
        if (!filter) {
          if (!setup) {
            setup += `?sortBy=${this.sortBy}`;
          } else {
            setup += `&sortBy=${this.sortBy}`;
          }
        } else {
          setup += `&sortBy=${this.sortBy}`;
        }
      }

      if (this.orderBy) {
        if (!filter) {
          if (!setup) {
            setup += `?orderBy=${this.orderBy}`;
          } else {
            setup += `&orderBy=${this.orderBy}`;
          }
        } else {
          setup += `&orderBy=${this.orderBy}`;
        }
      }

      this.preloader = true;
      let response = await this.getRequest(`apiLogs${filter}${setup}`);
      this.preloader = false;

      if (response) {
        this.headers = [
          { text: "ID", value: "id" },

          { text: "Datum", value: "created" },
          { text: "Type", value: "type" },
          { text: "Firmen ID", value: "companies_id" },
          { text: "Firmenname", value: "companyName" },
          { text: "Kampagnen ID", value: "campaigns_id" },
          { text: "Providername", value: "providerName" },
          { text: "Lead-ID", value: "leads_id" },
          { text: "Leadname", value: "leadFullName" },
          { text: "Request-Method", value: "requestMethod" },
          { text: "Response-Status-Code", value: "responseStatusCode" },
          { text: "Response-Body", value: "responseBody" },
          { text: "Request-Body", value: "requestBody" },
        ];

        this.data = response.data.data;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
